import React from 'react';
import './App.css';
import Display from './Display';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Copilots from './components/Copilots/CopilotsDisplay';
import GPTs from './components/GPTs/GptsDisplay';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Display />} />
          <Route path="/copilots" element={<Copilots />} />
          <Route path="/gpts" element={<GPTs />} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
