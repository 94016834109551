import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Hero from './Hero';
import ICFinfo from './ICFInfo';
import ProposalBudgetCopilot from './ProposalBudgetCopilot';
import CTA from './CTA';

function CopilotsDisplay() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]); // This effect runs whenever the location changes

  return (
    <div>
      <Hero />
      <ICFinfo />
      <ProposalBudgetCopilot />
      <CTA />
    </div>
  );
}

export default CopilotsDisplay;
