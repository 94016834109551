// Hero.js
import React from 'react';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';

function Hero() {
  const navigate = useNavigate();
  
  return (
    <div>
        <Header></Header>
        <div className='w-screen  flex items-center justify-center border-b border-gray-200'>
  <div className='flex flex-col md:flex-row items-center justify-center  mx-auto p-4'>
    <div className=' p-8 md:p-16 space-y-8 w-full md:w-1/2 max-w-[800px]'>
      <h2 className="text-3xl md:text-5xl font-bold text-gray-900 dark:text-white tracking-tight text-left">
        <span className='font-light' >Copilots | </span>
        Accelerating Precision in  
        <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent font-bold"> Clinical Documentation</span>
      </h2>
      <p className='text-left'>
        Leveraging the power of AI, Kenosha AI copilots dramatically streamline the creation of Informed Consent Forms (ICFs) and Proposal & Budget responses to RFIs and RFPs.
      </p>
      <div className='flex justify-start'>
        <button                 onClick={() => navigate('/#contact')}
 className="bg-[#191C85] hover:bg-[#191C85]/80 text-white transition duration-150 rounded-xl py-4 px-8 text-sm sm:text-base font-semibold">
          Contact Us
        </button>
      </div>
    </div>
    <div className='w-full md:w-1/2 p-4 md:pl-0 md:p-16 flex items-center justify-center'>
      <img src="https://i.imgur.com/7xQRuBC.png" alt="ai generated image" className="rounded-2xl w-full object-cover h-auto max-w-[500px]"/>
    </div>
  </div>
</div>

      

    </div>
  );
}

export default Hero;
