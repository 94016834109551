import React, { useState } from 'react';

function Contact() {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState(''); 
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    const formData = {
      email: email,
      name: subject,
      message: message,
    };

    try {
      const response = await fetch('https://api.kenosha.ai/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      alert('Your message has been sent successfully!');
      setEmail('');
      setSubject('');
      setMessage('');
    } catch (error) {
      console.error('Failed to send message:', error);
      alert('Failed to send message. Please try again later.');
    }
  };

  return (
    <section id="contact" className="bg-slate-100 dark:bg-gray-900"   style={{
      backgroundImage: "url('https://i.imgur.com/ZmATOxR.png')",
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      backgroundColor: '' 
    }}
  >
      <div className="py-8 pt-0 lg:py-12 px-4 mx-auto max-w-screen-md text-left ">
        <h2 className="mb-12 text-4xl tracking-tight font-bold text-center text-gray-900 dark:text-white">Contact Us</h2>
        <form onSubmit={handleSubmit} className="space-y-4 bg-white rounded-3xl p-12">
          <div className='flex flex-col items-start gap-y-4'>
            <p className='text-2xl font-semibold'>Drop us a message</p>
          <p className=" mb-4 font-medium  text-gray-500 dark:text-gray-400 text-left sm:text-md">Want a demo? Have a question? Want to talk AI efficiency? Please reach out!</p>

          </div>
          <div>
            <input type="text" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Name (Optional)" />
          </div>
          <div>
            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Email*" required />
          </div>
         
          <div className="sm:col-span-2">
            <textarea id="message" rows="6" value={message} onChange={(e) => setMessage(e.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a message..."></textarea>
          </div>
          <button 
  type="submit" 
  className="w-full py-3 px-5 text-sm font-medium text-center text-white rounded-lg hover:bg-[#171c84]/80 transition ease-in duration-50 focus:ring-4 focus:outline-none focus:ring-primary-300  bg-[#171c84]"
>
  Send
</button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
