// GPTsdisplay.js
import React, { useEffect } from 'react';
import Hero from './Hero.js'
import CTA from './CTA.js';
import CTA2 from './CTA2.js';
import Features from './Features.js';
import TryRegchat from './TryRegchat.js';
import Footer from '../Footer.js';
import { useLocation } from 'react-router-dom';



function GPTs() {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]); 



  return (
    <div>
<Hero>
  </Hero>    
  <CTA></CTA>
  <CTA2></CTA2>
  <Features></Features>
  <TryRegchat></TryRegchat>
  <Footer/>
  </div>
  );
}

export default GPTs;
