import React from 'react';
import LogoImageDark from '../assets/brand-images/LogoLong-DarkMode@2x.png';
import linkedin from '../assets/linkedin.svg'; // Ensure this path is correct
import { Link, useNavigate } from 'react-router-dom';

const navigate = (url) => {
  window.location.href = url;
};

const scrollToSection = (sectionId) => {
  const section = document.querySelector(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

function Footer() {
  return (
    <footer className="px-4 lg:px-6 py-4 dark:bg-gray-800" style={{ backgroundColor: '#171c84' }}>
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <Link to="/"> 
          <button
            type="button"
            className="flex items-center text-white hover:underline focus:outline-none mb-4 sm:mb-0"
            onClick={() => navigate('https://kenosha.ai')}
          > 
            <img src={LogoImageDark} className="mr-3 h-14 sm:h-14" alt="Kenosha AI Logo" style={{ minWidth: '48px' }} />
          </button></Link>
          <ul className="flex flex-col sm:flex-row flex-wrap items-center mb-6 text-sm font-medium text-slate-50 sm:mb-0">
            <li className="my-2 sm:my-0">
              <button
                type="button"
                className="text-white hover:underline me-4 md:me-6 focus:outline-none"
                onClick={() => navigate('https://kenosha.ai')}
                >
                Home
              </button>
            </li>
            <li className="my-2 sm:my-0">
            <Link to="/#team"> 
              <button
                type="button"
                className="text-white hover:underline me-4 md:me-6 focus:outline-none"
                // onClick={() => navigate('https://kenosha.ai/#team')}
              >
                Team
              </button>
         </Link>
            </li>

            <li className="my-2 sm:my-0">
            <Link to="/copilots"> 
  <button
    type="button"
    className="text-white hover:underline me-4 md:me-6 focus:outline-none"
    onClick={() => {
      // navigate to the copilots page
      window.scrollTo(0, 0);
    }}
  >
    Copilots
  </button>
</Link>

</li>

<li className="my-2 sm:my-0">

<Link to="/gpts"> 
  <button
    type="button"
    className="text-white  hover:underline me-4 md:me-6 focus:outline-none"
    onClick={() => {
      // navigate to the gpts page
      window.scrollTo(0, 0);
    }}
  >
    GPTs
  </button>
</Link>





            </li>

            <li className="my-2 sm:my-0">
            <Link to="/#contact"> 

              <button
                type="button"
                className="text-white hover:underline me-4 md:me-6 focus:outline-none"
                // onClick={() => navigate('/#contact')}
              >
                Contact
              </button>
              </Link> 

            </li>
            <li className="my-2 sm:my-0">
              <button
                type="button"
                className="focus:outline-none"
                onClick={() => navigate('https://www.linkedin.com/company/kenosha-ai/about/')}
              >
                <img
                  src={linkedin}
                  alt="LinkedIn"
                  className="h-8 w-8 filter invert" 
                />
              </button>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-slate-50 sm:text-center">© 2024 Kenosha AI™. All Rights Reserved.</span>
      </div>
    </footer>
  );
}

export default Footer;
