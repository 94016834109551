import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Hero from './components/Landing/Hero';
import Header from './components/Header';
import Footer from './components/Footer';
import InformationLong from './components/Landing/InformationLong';
import Contact from './components/Landing/Contact';
import Landing from './components/Landing/Landing';
import Team from './components/Landing/Team';
import TryRegchat from './components/Landing/TryRegchat';

function Display() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]); 

  return (
    <div className="bg-slate-50">
      <Header />
      <Landing />
      <InformationLong />
      <Team />
      <Contact />
      <TryRegchat />
      <Footer />
    </div>
  );
}

export default Display;
