import React from 'react';
import brad from "../../assets/brad.jpeg";
import bill from "../../assets/bill.jpeg";
import linkedin from "../../assets/linkedin.png";

// const TeamMemberCard = ({ name, title, description, imageUrl, profileUrl }) => {
//   return (


//   );
// };



function Team() {
//   const teamMembers = [
//     {
//       name: "Bill Kish",
//       title: "CEO & Co-Founder",
//       description: "Bill has over 30 years of experience at five successful startups, and is an accomplished technologist who has served as founding engineer, CEO, and CTO at startups and publicly traded companies that grew to over five billion in market cap.",
//       imageUrl: bill,
//       profileUrl: "https://www.linkedin.com/in/billkish/",
//     },
//     {
//       name: "Brad Pruitt, M.D.",
//       title: "President & Co-Founder",
//       description: "Dr. Pruitt has over 25 years of experience in clinical research and healthcare, with 13 years in the CRO industry, including serving as the founding CEO of a successfully acquired startup and as an executive medical director at top-five CROs.      ",
//       imageUrl: brad,
//       profileUrl: "https://www.linkedin.com/in/bradpruitt/",
//     },
//   ];

  return (
      <div id='team' className="p-12 md:p-20 flex flex-col gap-y-16 justify-center items-center">
     <div className='max-w-[1200px] '>
     <h2 className="text-left text-3xl sm:text-4xl font-extrabold text-gray-900  mb-8">Our Team</h2>

         <p className=" font-medium text-left text-gray-500 dark:text-gray-400 sm:text-xl">Kenosha AI was founded by Brad Pruitt, M.D., and Bill Kish. Together, they are leveraging the rapidly accelerating power of GPT-class AI to transform clinical research operations.</p>

        </div>
       
       <div className='flex flex-col gap-y-8 md:flex-row gap-x-8 max-w-[1200px] '>
       <div className='w-full flex flex-col gap-y-8 border rounded-2xl p-8 bg-white'>
        <img src={bill} className='rounded-full w-40'></img>
        <div className='flex flex-col text-left gap-y-4'>
        <div className='flex flex-col md:flex-row gap-x-4 gap-y-4 items-start md:items-center '>
          <h1 className='text-2xl font-bold text-slate-900 text-nowrap' >Bill Kish</h1>
        <h2 className='text-slate-50 text-sm font-medium bg-gradient-to-r from-slate-900  to-[#191C85] rounded-full px-3 py-1 w-fit text-nowrap'>CEO & Co-Founder</h2></div>
        <p className='text-gray-500'>Bill has 30+ years of experience at five successful startups, and is an accomplished technologist who has served as founding engineer, CEO, & CTO at startups and publicly traded companies that grew to over 5 billion in market cap.</p>
        </div>

        <a href={"https://www.linkedin.com/in/billkish/"} target="_blank" rel="noopener noreferrer" 
          className="inline-flex items-center justify-center px-4 py-2 border  text-base font-medium rounded-xl text-[#0f1466] border-[#171C84] hover:bg-[#0f1466] hover:text-white transition duration-300 ease-in-out transform hover:scale-105 text-sm">
          <img src={linkedin} alt="LinkedIn"     className="h-4 w-4 filter  mr-2" 
 /> More about Bill
        </a>
       </div>
       <div className='w-full flex flex-col gap-y-8 border rounded-2xl p-8 bg-white'>
       <div style={{
  backgroundImage: `url(${brad})`,
  backgroundSize: '175%',
  backgroundPosition: 'center 30%', 
  width: '160px',
  height: '160px',
  borderRadius: '50%',
  borderWidth:'1px',
  borderColor:"#EDEDED"
}}></div>
        <div className='flex flex-col text-left gap-y-4'>
        <div className='flex flex-col md:flex-row gap-x-4 gap-y-4 items-start md:items-center '>
        <h1 className='text-2xl font-bold text-slate-900 text-nowrap'>Brad Pruitt</h1>
        <h2 className='text-slate-50 text-sm font-medium bg-gradient-to-r from-slate-900  to-[#191C85] rounded-full px-3 py-1 w-fit text-nowrap'>President & Co-Founder</h2>     
          </div>
         <p className='text-gray-500'>Dr. Pruitt has 25+ years of experience in clinical research & healthcare, with 13 years in the CRO industry, including serving as the founding CEO of an acquired startup and as an executive medical director at top-5 CROs.</p>
        </div>
        <a href={"https://www.linkedin.com/in/bradpruitt/"} target="_blank" rel="noopener noreferrer" 
          className="inline-flex items-center justify-center px-4 py-2 border  text-base font-medium rounded-xl text-[#0f1466] border-[#171C84] hover:bg-[#0f1466] hover:text-white transition duration-300 ease-in-out transform hover:scale-105 text-sm">
          <img src={linkedin} alt="LinkedIn"     className="h-4 w-4 filter  mr-2" 
 /> More about Brad
        </a>

       </div>

       
       </div>
       

      </div>

// <section id="team" className="bg-white dark:bg-gray-900">
//   <div className="mx-auto px-4 py-8 sm:p-12 lg:p-16">
//     <div className="flex flex-wrap justify-between">
//       
//       <div className="w-full lg:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-8">
//         {teamMembers.map(member => (
//           <TeamMemberCard key={member.name} {...member} />
//         ))}
//       </div>
//     </div>
//   </div>
// </section>

  );
}

export default Team;