import React from 'react';
import Footer from "../Footer"

import background from '../../assets/gradientbackground.png';
import { useNavigate } from 'react-router-dom';


function CTA() {

    const navigate = useNavigate();

    const goToContact = () => {
      navigate('/#contact'); 
    };


  return (
    <div style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
<div className="min-h-[350px] flex flex-col items-center justify-center space-y-8 p-12">

<p className='text-3xl md:text-5xl font-bold tracking-tight'>Are you ready to get started?</p>
<p className='text-gray-500 text-lg'>Contact us to talk about pricing plans, custom copilots, GPTs, feature rollouts, enterprise plans, and more.</p>

<button className='bg-black hover:bg-black/80 text-white p-3 px-6 rounded-xl font-semibold'       onClick={goToContact} >Get in touch</button>

    </div>
           <Footer></Footer>


    </div>
  );
}

export default CTA;
