import React, { useState } from 'react';
import LogoImageDark from '../assets/brand-images/logobluetext.png'; // Import the logo image for dark mode
import { Link, useNavigate } from 'react-router-dom';


function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigateTo = (url) => {
    window.location.href = url;
  };

  const navigate = useNavigate();


  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };


  const navigateToICFInfo = () => {
    navigate('/copilots#icfinfo');
  };

  const [isCopilotsOpen, setIsCopilotsOpen] = useState(false);


  return (
    <header className="border-b shadow-sm" style={{ backgroundColor: '#F8FAFC' }}>
      <nav className="px-4 md:px-8 py-4">
        <div className="flex justify-between items-center mx-auto max-w-screen-xl">
          <button onClick={() => navigateTo('https://kenosha.ai')} className="flex items-center focus:outline-none">
            <img src={LogoImageDark} className="h-8" alt="Kenosha AI Logo" />
          </button>
          <div className="hidden md:flex flex-grow items-center justify-end">
            <div className="flex flex-row items-center gap-2 md:gap-2">
            <Link to="/">
              <button
                type="button"
                className="text-gray-900 hover:bg-gray-100 rounded-lg p-2  focus:outline-none transition-colors duration-150"
              >
                Home
              </button>
              
              </Link>
              <button
                type="button"
                className="text-gray-900 hover:bg-gray-100 rounded-lg p-2  focus:outline-none transition-colors duration-150"
                onClick={() => navigate('/#team')}
              >
                Team
              </button>
              <div className="relative group">
                <Link to="/copilots">
                  <button className="text-gray-900 hover:bg-gray-100 rounded-lg p-2 focus:outline-none transition-colors duration-150">
                    Copilots

                  </button> </Link>
                <div className="absolute hidden group-hover:block bg-white shadow-lg rounded-xl p-4" style={{ left: '50%', transform: 'translateX(-50%)' }}>
                  <div className='flex flex-col gap-y-4'>
                  <Link to="/copilots">      <button className="text-left w-full text-nowrap hover:bg-gray-50 p-2 rounded-md" >Informed Consent Forms (ICFs)</button> </Link>
                <Link to = "/copilots#proposals-budgets">    <button className="text-left w-full text-nowrap hover:bg-gray-50 p-2 rounded-md" onClick={() => navigateTo('/copilots#proposals-budgets')}>Proposals & Budgets</button></Link>
                  </div>
                </div>
              </div>

              <div className="relative group">
                <div className="relative group">
                  <Link to="/gpts">  <button className="text-gray-900 hover:bg-gray-100 rounded-lg p-2 focus:outline-none transition-colors duration-150">
                    GPTs

                  </button>
                  </Link>

                  <div className="absolute hidden group-hover:block bg-white shadow-lg rounded-xl p-4" style={{ left: '50%', transform: 'translateX(-50%)' }}>
                    <div className='flex flex-col gap-y-4'>
                    <Link to="/gpts/#customenterprise">        <button className="text-left w-full text-nowrap hover:bg-gray-50 p-2 rounded-md">Custom Enterprise GPTs</button> </Link>
                    <Link to="/gpts/#tryregchat">        <button className="text-left w-full text-nowrap hover:bg-gray-50 p-2 rounded-md" >RegChat™</button></Link>
                    </div>
                  </div>
                </div>


              </div>
              <Link to="/#contact"> 
              <button
                className="border-2 border-[#191C85] hover:bg-[#191C85] text-[#191C85] hover:text-gray-100 transition ease-in duration-150 rounded-xl py-3 px-8 ml-4 font-semibold"
              >
                Contact
              </button>
              </Link>
            </div>
          </div>
          <div className="md:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-900 focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" /></svg>
            </button>
          </div>
        </div>
      </nav>
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="flex flex-col items-start gap-4 p-4">
        <Link to="/" className='w-full'>
          <button
            type="button"
            className="text-gray-900 hover:bg-gray-100 rounded-lg p-2 w-full text-left focus:outline-none transition-colors duration-150"
          >
            Home
          </button> </Link>

          <Link to="/#team" className='w-full'>
          <button
            type="button"
            className="text-gray-900 hover:bg-gray-100 rounded-lg p-2 w-full text-left focus:outline-none transition-colors duration-150"
            // onClick={() => scrollToSection('#team')}
          >
            Team
          </button> </Link>
          
          <Link to="/copilots" className='w-full'>
          <button
            type="button"
            className="text-gray-900 hover:bg-gray-100 font-semibold rounded-lg p-2 w-full text-left focus:outline-none transition-colors duration-150"
          >
            Copilots
          </button>
       </Link>

                 

          <div className="w-full  group-hover:block bg-white border rounded-xl p-4 ">
            <div className='flex flex-col gap-y-4 '>


            <Link to="/copilots#icfs">     <button className="text-left w-full text-nowrap hover:bg-gray-50 p-2 rounded-md " >Informed Consent Forms (ICFs)</button> </Link>
            <Link to="/copilots#proposals-budgets">      <button className="text-left w-full text-nowrap hover:bg-gray-50 p-2 rounded-md" >Proposals & Budgets</button> </Link>
            </div>
          </div>


          <Link to="/gpts" className='w-full'>  
          <button
            type="button"
            className="w-full text-gray-900 hover:bg-gray-100 font-semibold rounded-lg p-2  text-left focus:outline-none transition-colors duration-150"
          >
            GPTs
          </button> </Link>
          <div className="w-full  group-hover:block bg-white border rounded-xl p-4 ">
            <div className='flex flex-col gap-y-4 '>

            <Link to="/gpts/#customenterprise">      <button className="text-left w-full text-nowrap hover:bg-gray-50 p-2 rounded-md " >Custom Enterprise GPTs</button> </Link>
            <Link to="/gpts/#tryregchat">    <button className="text-left w-full text-nowrap hover:bg-gray-50 p-2 rounded-md" >RegChat™</button> </Link>
            </div>
          </div>
          <Link to="/#contact"> 
          <button
            className="border-2 border-[#191C85] hover:bg-[#191C85] text-[#191C85] hover:text-[white]  transition ease-in duration-150 rounded-xl py-3 px-8  font-semibold w-full text-center"
          >
            Contact
          </button>
          </Link>
        </div>
      </div>
    </header>


  );
}

export default Header;
