// ICFInfo.js
import React from 'react';
import customicon from "../../assets/icons/Icon6.png"
import customicon2 from "../../assets/icons/Icon7.svg"
import documentready from "../../assets/ICFcopilot.png"


function Hero() {
  return (
<div  id = "icfinfo" class='w-screen flex flex-col items-center justify-start border-b-2 p-4 md:p-16 space-y-4 md:space-y-8 bg-[#F1F1F8]/50'>
<div class='max-w-screen-xl mx-auto w-full'>
<div class='w-full flex flex-col space-y-2 items-start px-4 md:px-20 md:py-0 py-4 pb-0 '>
        <span class="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent  tracking-tight text-sm md:text-xl font-bold text-left">ICF Generation</span>
        <p class='text-2xl md:text-4xl font-bold text-gray-900 dark:text-white tracking-tight text-left md:text-nowrap'>Informed Consent Form (ICF) Copilot</p>
    </div>

    <div class='flex flex-col md:flex-row items-center justify-center w-full gap-4 md:gap-y-4 md:gap-x-8 px-4 md:pr-8 py-4 md:py-8'>
        <div class='bg-white border rounded-xl md:rounded-3xl p-4 md:p-14 w-full md:w-1/3 md:max-w-[500px] min-w-[350px] flex flex-col items-center'>
            <div class='flex flex-col space-y-12 items-center'>
                <div class='flex flex-col space-y-2 items-start text-left'>
                    <img src={customicon} alt="Icon" class="mb-3 w-8 h-8 md:w-10 md:h-10" />
                    <p class='text-lg md:text-2xl tracking-tight font-bold text-[#191C85] text-left'>Efficient Draft Generation</p>
                    <p className='text-sm md:text-base text-left text-gray-500'>Using standard operating procedures (SOPs), checklists, and country-specific templates, our AI rapidly generates nearly complete core and country-specific master informed consent form (ICF) drafts.</p>
                </div>

                <div class='flex flex-col space-y-2 items-start text-left'>
                    <img src={customicon2} alt="Icon" class="mb-3 w-8 h-8 md:w-10 md:h-10" />
                    <p class='text-lg md:text-2xl tracking-tight font-bold text-[#191C85] text-left'>Protocol-Based Drafting</p>
                    <p className='text-sm md:text-base text-left text-gray-500'>Derives drafts from protocols and investigator brochures, enabling medical writing and regulatory teams to finalize the ICFs in a fraction of the time.</p>
                </div>
            </div>
        </div>

        <div class='w-full md:w-2/3 flex justify-center items-center rounded-xl md:rounded-3xl  mt-8 md:mt-0 md:min-w-[500px]  max-w-full'>
            <img src={documentready} alt="Document Ready" class="max-w-full h-auto "></img>
        </div>
    </div>
</div>
   
</div>


  );
}

export default Hero;
