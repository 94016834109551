// Hero.js
import React from 'react';
import Header from '../Header';

import { useNavigate } from 'react-router-dom';

function Hero() {
  const navigate = useNavigate();

  return (
    <div>
        <Header></Header>
        <div className='w-screen  flex items-center justify-center border-b border-gray-200'>
  <div className='flex flex-col md:flex-row items-center justify-center  mx-auto p-4 '>
  <div className='w-full md:w-1/2 p-4 md:p-16 md:pr-0 flex items-center justify-center '>
      <img src="https://i.imgur.com/5gco6Ay.png" alt="ai generated image" className="rounded-2xl w-full object-cover h-auto max-w-[500px]"/>
    </div>
    <div className=' p-8 md:p-16 space-y-8 w-full md:w-1/2 max-w-[800px]'>
      <h2 className="text-3xl md:text-5xl font-bold text-gray-900 dark:text-white tracking-tight text-left">
        <span className='font-light'>GPTs | </span>
        <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent font-bold"> Conversational AI </span> 
        for external and internal intelligence   

      </h2>
      <p className='text-left'>
      Streamline your clinical research operations with AI, enabling efficient access and integration of internal and external data for enhanced operational precision and effectiveness.      </p>
      <div className='flex flex-row space-x-6 justify-start'>
        <button className="bg-[#191C85] hover:bg-[#191C85]/80 text-nowrap text-white transition duration-150 rounded-xl py-4 px-8 text-sm sm:text-base font-semibold" onClick={() => window.open('https://regchat.com', '_blank')}
          >
          Try it out
        </button>
        <button className="border border-gray-300 text-nowrap hover:bg-gray-50 text-gray-800 transition duration-150 rounded-xl py-4 px-8 text-sm sm:text-base font-semibold" onClick={() => {
    navigate('/#contact');   }}
>
          Contact Us
        </button>
      </div>
    </div>
  
  </div>
</div>

      

    </div>
  );
}

export default Hero;
