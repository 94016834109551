// ICFInfo.js
import React from 'react';
import customicon from "../../assets/icons/Icon6.png"
import customicon2 from "../../assets/icons/Icon7.svg"
import documentready from "../../assets/documentready.svg"
import laptopexample from "../../assets/laptopexample.png"



function ProposalBudgetCopilot() {
  return (
<div id = "proposals-budgets" class='w-screen flex flex-col items-center justify-start border-b-2 p-4 md:p-16 space-y-4 md:space-y-8 '>
<div class='max-w-screen-xl mx-auto w-full'>


    <div class='flex flex-col md:flex-row items-center justify-center w-full gap-4 md:gap-y-4 md:gap-x-8 px-4 md:pr-8 py-4 md:py-8'>
    <div class='w-full md:w-2/3 flex justify-center items-center rounded-xl md:rounded-3xl  mt-8 md:mt-0 md:min-w-[500px]  max-w-full'>
            <img src={laptopexample} alt="Document Ready" class="max-w-full h-auto"></img>
        </div>
        <div class='bg-white p-4 md:p-10 w-full md:w-1/2 md:max-w-[500px] min-w-[350px] flex flex-col items-center'>
            <div class='flex flex-col space-y-4 items-start'>
                    <div className='items-start text-left flex flex-col space-y-2 '>
                    <span class="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent  tracking-tight text-sm md:text-xl font-bold text-left">AI-Assisted Generation</span>
        <p class='text-2xl md:text-4xl font-bold text-gray-900 dark:text-white tracking-tight text-left '>Proposal & Budget Copilot</p>

                    </div>

                <div class='flex flex-col space-y-2 items-start text-left'>
                    <p className='text-sm md:text-base text-left text-gray-500 m-1'>AI-assisted workflow for RFI/RFP team management, summarization and extraction of key metrics from protocols and RFP documents for budget generation leveraging pricing models, and generative AI to draft responses to RFIs, RFPs, vendor assessments, and security questionnaires based on content libraries and past submissions.</p>
                </div>

               
            </div>
        </div>

      
    </div>
</div>
   
</div>


  );
}

export default ProposalBudgetCopilot;
